import React, { useMemo, useState, useEffect, useCallback } from "react";
import { authHeader } from "../../../_helpers"
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Form from "antd/lib/form";
import axios from "axios";
import Select from "antd/lib/select";
const apiUrl = process.env.REACT_APP_API;

const useOptions = () => {
    const options = useMemo(
        () => ({
            style: {
                base: {
                    fontSize: '15px',
                    color: "#424770",
                    letterSpacing: "0.025em",
                    "::placeholder": {
                        color: "#aab7c4"
                    }
                },
                invalid: {
                    color: "#9e2146"
                }
            }
        }),
        []
    );

    return options;
};

const CardForm = (props) => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        GradesList: [],
    })
    let { price, discount } = props

    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    const handleSubmit = async event => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card);

        if (result.error) {
            // Show error to your customer.
            console.log(result.error.message);
        } else {
            if (!result || !result.token || !result.token.id) {
                console.error(result);
                return true
            }
            props.handleToken(result.token.id)
        }
    };

    const getGradesList = async () => {
        let res = await axios.get(`${apiUrl}/student/grades`);
        let { data } = res;
        if (data.error === null) {
            return data.payload
        }
    }


    const getProfileInfo = async () => {
        let res = await axios.get(`${apiUrl}/student`, {
            headers: authHeader()
        })

        let { data } = res

        if (data.error === null) {
            return {
                code: 200,
                payload: data.payload
            }
        }

        return {
            code: 400,
            message: data.error.message ? data.error.message : 'Something went wrong'
        }
    };


    const handleNext = async v => {

        let res = await axios.put(`${apiUrl}/student`, { gradeId: v.gradeId, centerId: state.centerId }, {
            headers: authHeader()
        });
        setState(prevState => {
            return {
                ...prevState,
                secondStep: true
            }
        })
    }


    const init = useCallback(() => {
        getGradesList().then(gradesRes => {

            setState(prevState => {
                return {
                    ...prevState,
                    secondStep: false,
                    GradesList: gradesRes,
                }
            })

        })
        getProfileInfo().then(res => {
            let { code, payload } = res
            if (code === 200) {
                let {
                    email, gradeId, centerId
                } = payload

                form.setFieldsValue({
                    gradeId,
                })
                setState(prevState => {
                    return {
                        ...prevState,
                        loader: false,
                        centerId: centerId,
                        email
                    }
                })
            }
        })
    }, [
        form
    ])


    useEffect(() => {
        console.log('useEffect called')
        init()
    }, [init]);


    let {

        GradesList,

    } = state

    return (
        <>


            {
                state.secondStep ? (



                    <form className="stripe-form" onSubmit={handleSubmit}>
                        <label>

                            <div className="text">Card details</div>
                            <CardElement
                                options={options}
                                onReady={() => {
                                    // console.log("CardElement [ready]");
                                }}
                                onChange={event => {
                                    // console.log("CardElement [change]", event);
                                }}
                                onBlur={() => {
                                    // console.log("CardElement [blur]");
                                }}
                                onFocus={() => {
                                    // console.log("CardElement [focus]");
                                }}
                            />
                        </label>
                        <button className="btn btn-small bg-pink color-white whit-border border-color-pink" type="submit"
                            disabled={!stripe}>
                            Pay ${discount > 0 ? price - discount : price}
                        </button>
                    </form>
                )
                    :
                    (


                        <div >
                            <label>

                                <Form
                                    form={form}
                                    name="grade_update"
                                    onFinish={handleNext}
                                >
                                    <div className="form-box">

                                        <Form.Item
                                            name="gradeId"
                                            label="Please confirm the grade"
                                            rules={[{ required: true }]}
                                        >

                                            <Select
                                                virtual={false}
                                                autoComplete={new Date().valueOf()}
                                                showSearch
                                                optionFilterProp="children"
                                                placeholder="Select current Grade in school"
                                                allowClear
                                                showArrow
                                            >
                                                {Object.keys(GradesList).map((key) => {
                                                    let listItem = GradesList[key]
                                                    return (
                                                        <Select.Option key={key}
                                                            value={listItem.id}>{listItem.label}</Select.Option>
                                                    )
                                                })}
                                            </Select>

                                        </Form.Item>
                                        <Form.Item shouldUpdate={true}>
                                            {() => (
                                                <button
                                                    className="btn btn-big whit-border border-color-pink bg-pink color-white"
                                                    htmltype="submit">
                                                    Save
                                                </button>
                                            )}
                                        </Form.Item>

                                    </div>
                                </Form>
                            </label>
                        </div>
                    )
            }
        </>

    );
};

export default CardForm;
