/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from "react";
import axios from "axios";
import {authHeader, getLocalStorage} from "../../../_helpers";
import pandaLogo from "../../../Assets/svg/logo-big.svg"
import Stripe from "./stripe";
import Modal from "antd/es/modal";
import {signout} from "../../../actions";

const apiUrl = process.env.REACT_APP_API;


const StudentInner = (props) => {
    const userInfo = getLocalStorage('user', true)
    const [state, setState] = useState({
        loader: true,
        purchased: false,
        urls: '',
        testURL: '',
        showModal: false,
        purchaseInfo: {}
    })

    useEffect(() => {
        document.body.className = 'page-student-inner';

        // getAvailablePurchase().then(res => {
        //     let {code, payload} = res
        //     if (code === 200) {
        //         if (payload.hasOwnProperty('urls')) {
        //             setState(prevState => {
        //                 return {
        //                     ...prevState,
        //                     purchased: true,
        //                     urls: payload.urls,
        //                     loader: false
        //                 }
        //             })
        //             return true
        //         }
        //         setState(prevState => {
        //             return {
        //                 ...prevState,
        //                 purchaseInfo: payload,
        //                 loader: false
        //             }
        //         })

        //     } else {

        //     }

        // })
        getAvailablePurchaseAll().then(res => {
            let {code, payload} = res
            if (code === 200) {
                if (payload.hasOwnProperty('tests')) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            purchased: true,
                            tests: payload.tests,
                            solution: payload.solution,
                            loader: false
                        }
                    })
                    return true
                }
                setState(prevState => {
                    return {
                        ...prevState,
                        purchaseInfo: payload,
                        loader: false
                    }
                })

            } else {

            }

        })
        return () => {
            document.body.className = '';
        }
    }, []);

    const logout = () => {
        signout()
        window.location.replace(`${process.env.REACT_APP_STUDENT_URL}/register`);
    }

    const getAvailablePurchase = async () => {

        let res = await axios.get(`${apiUrl}/student/availablePurchase`, {
            headers: authHeader()
        })

        let {data} = res

        if (data.error === null) {
            return {
                code: 200,
                payload: data.payload
            }
        } else {

        }
    }

    const getAvailablePurchaseAll = async () => {

        let res = await axios.get(`${apiUrl}/student/availablePurchaseAll`, {
            headers: authHeader()
        })

        let {data} = res

        if (data.error === null) {
            return {
                code: 200,
                payload: data.payload
            }
        } else {

        }
    }

    const Purchase = async (token) => {
        let loaderBox = document.getElementById('transparent-loader-box');
        loaderBox.style.display = 'flex'
        let {key} = purchaseInfo
        const postData = {
            productKey: key,
            token
        }
        let res = await axios.post(`${apiUrl}/student/purchase`, postData, {
            headers: authHeader()
        })

        let {data} = res
        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    showModal: false
                }
            })
            window.location.reload(false);
            loaderBox.style.display = 'none'
        } else {

        }
    }

    const handleToken = data => {
        Purchase(data).then()
    }
    // let {loader, purchased, purchaseInfo, urls, showModal} = state
    let {loader, purchased, purchaseInfo, tests, solution, showModal, testURL} = state
    let {discount, price} = purchaseInfo
    // let {olympiad_id, solution} = urls
    // let {olympiad_id} = urls

    const TestButtons = () => {
        return (
            <>
                <div className="button-wrap competitionLine">
                <div className="btn-long competitionTitle">{tests[0].title}</div>
                {!tests[0].isActive ? (
                    tests[0].solution ? (
                        <a rel="noopener noreferrer" target="_blank" href={tests[0].solution} className="fw-600 competitionButton btn btn-small whit-border border-color-viking color-white bg-viking">Solutions</a>
                    ) : (
                        <div className="fw-600 competitionButton btn btn-small whit-border border-color-viking color-white bg-viking disabled">July 15</div>
                    )
                    
                ) : ( <div onClick={() => { setState(prevState => { return { ...prevState, testURL: tests[0].url, showModal: true }})
                    }} className="fw-600 competitionButton btn btn-small whit-border border-color-viking color-white bg-viking">START TEST</div>
                )}
                </div>
                
                <div className="button-wrap  competitionLine">
                <div className="btn-long competitionTitle">{tests[1].title}</div>
                {!tests[1].isActive ? (
                    tests[1].solution ? (
                        <a rel="noopener noreferrer" target="_blank" href={tests[1].solution} className="fw-600 competitionButton btn btn-small whit-border border-color-viking color-white bg-viking">Solutions</a>
                    ) : (
                    <div className="fw-600 competitionButton btn btn-small whit-border border-color-viking color-white bg-viking disabled">August 15</div>
                    )
                    ) : ( <div onClick={() => { setState(prevState => { return { ...prevState, testURL: tests[1].url, showModal: true }})
                    }} className="fw-600 competitionButton btn btn-small whit-border border-color-viking color-white bg-viking">START TEST</div>
                )}
                </div>
                
                <div className="button-wrap  competitionLine">
                <div className="btn-long competitionTitle">{tests[2].title}</div>
                {!tests[2].isActive ? (
                    tests[2].solution ? (
                        <a rel="noopener noreferrer" target="_blank" href={tests[2].solution} className="fw-600 competitionButton btn btn-small whit-border border-color-viking color-white bg-viking">Solutions</a>
                    ) : (
                    <div className="fw-600 competitionButton btn btn-small whit-border border-color-viking color-white bg-viking  disabled">September 15</div>
                    )
                    ) : ( <div onClick={() => { setState(prevState => { return { ...prevState, testURL: tests[2].url, showModal: true }})
                    }} className="fw-600 competitionButton btn btn-small whit-border border-color-viking color-white bg-viking">START TEST</div>
                )}
                </div>
                <br/>
                
                <div className="button-wrap  competitionLine">
                <div className="fw-600 btn-long competitionTitle">{tests[3].title}</div>
                {!tests[3].isActive ? (
                    tests[3].solution ? (
                        // <a rel="noopener noreferrer" target="_blank" href={tests[3].solution} className="fw-600 competitionButton btn btn-small whit-border border-color-pink color-white bg-pink">Solutions</a>
                        <></>
                    ) : (
                    <div className="fw-600 competitionButton btn btn-small whit-border border-color-pink color-white bg-pink disabled">November 15</div>
                    )
                    ) : ( <div onClick={() => { setState(prevState => { return { ...prevState, testURL: tests[3].url, showModal: true }})
                    }} className="fw-600 competitionButton btn btn-small whit-border border-color-pink color-white bg-pink">START OLYMPIAD</div>
                )}
                </div>
                {/* {!olympiad_id || olympiad_id === '' ? (
                    <div
                        className="btn btn-long whit-border border-color-viking color-white bg-viking disabled">Start
                        Competition
                    </div>
                ) : (
                    <div onClick={() => {
                        setState(prevState => {
                            return {
                                ...prevState,
                                showModal: true
                            }
                        })
                    }} className="btn btn-long whit-border border-color-viking color-white bg-viking">Start Competition
                    </div>
                )} */}

                {/*{!solution || solution === '' ? (*/}
                {/*    <div className="btn btn-long whit-border border-color-ronchi color-white bg-ronchi disabled">Download Solutions*/}
                {/*    </div>*/}
                {/*) : (*/}
                {/*    <a rel="noopener noreferrer" target="_blank" href={solution}*/}
                {/*       className="btn btn-long whit-border border-color-pink color-white bg-pink m-t-0">Download Solutions</a>*/}
                {/*)}*/}


                {/*<a rel="noopener noreferrer" target="_blank" href="https://student.spacepandamath.org/register"*/}
                {/*   className="btn btn-long whit-border border-color-ronchi color-white bg-ronchi">Add Student or Grade</a>*/}
                {/* <div className="btn btn-long whit-border border-color-green color-white bg-green" onClick={logout}>
                    Add Student or Grade
                </div> */}

            </>
        )
    }
    return (
        <>
            {loader ? (
                <div id="loader-box" className="loader-box">
                    <div className="atom">
                        <div className="electron"/>
                        <div className="electron"/>
                        <div className="electron"/>
                    </div>
                </div>
            ) : null}
            <div id="transparent-loader-box" className="loader-box transparent" style={{display: 'none'}}>
                <div className="atom">
                    <div className="electron"/>
                    <div className="electron"/>
                    <div className="electron"/>
                </div>
            </div>
            {!purchased ? (
                <Modal
                    centered
                    closable={true}
                    visible={showModal}
                    footer={null}
                    className="stripe-modal"
                    onCancel={() => {
                        setState(prevState => {
                            return {
                                ...prevState,
                                showModal: false
                            }
                        })
                    }}
                >
                    <div className="ant-modal-title">Complete Registration</div>
                    <Stripe handleToken={handleToken} price={price} discount={discount}/>
                    <svg className="stripe-logo" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 150 34">
                        <path fill="#635bff"
                              d="M146,0H3.73A3.73,3.73,0,0,0,0,3.73V30.27A3.73,3.73,0,0,0,3.73,34H146a4,4,0,0,0,4-4V4A4,4,0,0,0,146,0Zm3,30a3,3,0,0,1-3,3H3.73A2.74,2.74,0,0,1,1,30.27V3.73A2.74,2.74,0,0,1,3.73,1H146a3,3,0,0,1,3,3Z"/>
                        <path fill="#635bff"
                              d="M17.07,11.24h-4.3V22h1.92V17.84h2.38c2.4,0,3.9-1.16,3.9-3.3S19.47,11.24,17.07,11.24Zm-.1,5H14.69v-3.3H17c1.38,0,2.11.59,2.11,1.65S18.35,16.19,17,16.19Z"/>
                        <path fill="#635bff"
                              d="M25.1,14a3.77,3.77,0,0,0-3.8,4.09,3.81,3.81,0,1,0,7.59,0A3.76,3.76,0,0,0,25.1,14Zm0,6.67c-1.22,0-2-1-2-2.58s.76-2.58,2-2.58,2,1,2,2.58S26.31,20.66,25.1,20.66Z"/>
                        <polygon fill="#635bff"
                                 points="36.78 19.35 35.37 14.13 33.89 14.13 32.49 19.35 31.07 14.13 29.22 14.13 31.59 22.01 33.15 22.01 34.59 16.85 36.03 22.01 37.59 22.01 39.96 14.13 38.18 14.13 36.78 19.35"/>
                        <path fill="#635bff"
                              d="M44,14a3.83,3.83,0,0,0-3.75,4.09,3.79,3.79,0,0,0,3.83,4.09A3.47,3.47,0,0,0,47.49,20L46,19.38a1.78,1.78,0,0,1-1.83,1.26A2.12,2.12,0,0,1,42,18.47h5.52v-.6C47.54,15.71,46.32,14,44,14Zm-1.93,3.13A1.92,1.92,0,0,1,44,15.5a1.56,1.56,0,0,1,1.69,1.62Z"/>
                        <path fill="#635bff"
                              d="M50.69,15.3V14.13h-1.8V22h1.8V17.87a1.89,1.89,0,0,1,2-2,4.68,4.68,0,0,1,.66,0v-1.8c-.14,0-.3,0-.51,0A2.29,2.29,0,0,0,50.69,15.3Z"/>
                        <path fill="#635bff"
                              d="M57.48,14a3.83,3.83,0,0,0-3.75,4.09,3.79,3.79,0,0,0,3.83,4.09A3.47,3.47,0,0,0,60.93,20l-1.54-.59a1.78,1.78,0,0,1-1.83,1.26,2.12,2.12,0,0,1-2.1-2.17H61v-.6C61,15.71,59.76,14,57.48,14Zm-1.93,3.13a1.92,1.92,0,0,1,1.92-1.62,1.56,1.56,0,0,1,1.69,1.62Z"/>
                        <path fill="#635bff"
                              d="M67.56,15a2.85,2.85,0,0,0-2.26-1c-2.21,0-3.47,1.85-3.47,4.09s1.26,4.09,3.47,4.09a2.82,2.82,0,0,0,2.26-1V22h1.8V11.24h-1.8Zm0,3.35a2,2,0,0,1-2,2.28c-1.31,0-2-1-2-2.52s.7-2.52,2-2.52c1.11,0,2,.81,2,2.29Z"/>
                        <path fill="#635bff"
                              d="M79.31,14A2.88,2.88,0,0,0,77,15V11.24h-1.8V22H77v-.83a2.86,2.86,0,0,0,2.27,1c2.2,0,3.46-1.86,3.46-4.09S81.51,14,79.31,14ZM79,20.6a2,2,0,0,1-2-2.28v-.47c0-1.48.84-2.29,2-2.29,1.3,0,2,1,2,2.52S80.25,20.6,79,20.6Z"/>
                        <path fill="#635bff"
                              d="M86.93,19.66,85,14.13H83.1L86,21.72l-.3.74a1,1,0,0,1-1.14.79,4.12,4.12,0,0,1-.6,0v1.51a4.62,4.62,0,0,0,.73.05,2.67,2.67,0,0,0,2.78-2l3.24-8.62H88.82Z"/>
                        <path fill="#635bff"
                              d="M125,12.43a3,3,0,0,0-2.13.87l-.14-.69h-2.39V25.53l2.72-.59V21.81a3,3,0,0,0,1.93.7c1.94,0,3.72-1.59,3.72-5.11C128.71,14.18,126.91,12.43,125,12.43Zm-.65,7.63a1.61,1.61,0,0,1-1.28-.52l0-4.11a1.64,1.64,0,0,1,1.3-.55c1,0,1.68,1.13,1.68,2.58S125.36,20.06,124.35,20.06Z"/>
                        <path fill="#635bff"
                              d="M133.73,12.43c-2.62,0-4.21,2.26-4.21,5.11,0,3.37,1.88,5.08,4.56,5.08a6.12,6.12,0,0,0,3-.73V19.64a5.79,5.79,0,0,1-2.7.62c-1.08,0-2-.39-2.14-1.7h5.38c0-.15,0-.74,0-1C137.71,14.69,136.35,12.43,133.73,12.43Zm-1.47,4.07c0-1.26.77-1.79,1.45-1.79s1.4.53,1.4,1.79Z"/>
                        <path fill="#635bff"
                              d="M113,13.36l-.17-.82h-2.32v9.71h2.68V15.67a1.87,1.87,0,0,1,2.05-.58V12.54A1.8,1.8,0,0,0,113,13.36Z"/>
                        <path fill="#635bff"
                              d="M99.46,15.46c0-.44.36-.61.93-.61a5.9,5.9,0,0,1,2.7.72V12.94a7,7,0,0,0-2.7-.51c-2.21,0-3.68,1.18-3.68,3.16,0,3.1,4.14,2.6,4.14,3.93,0,.52-.44.69-1,.69a6.78,6.78,0,0,1-3-.9V22a7.38,7.38,0,0,0,3,.64c2.26,0,3.82-1.15,3.82-3.16C103.62,16.12,99.46,16.72,99.46,15.46Z"/>
                        <path fill="#635bff"
                              d="M107.28,10.24l-2.65.58v8.93a2.77,2.77,0,0,0,2.82,2.87,4.16,4.16,0,0,0,1.91-.37V20c-.35.15-2.06.66-2.06-1V15h2.06V12.66h-2.06Z"/>
                        <polygon fill="#635bff" points="116.25 11.7 118.98 11.13 118.98 8.97 116.25 9.54 116.25 11.7"/>
                        <rect fill="#635bff" x="116.25" y="12.61" width="2.73" height="9.64"/>
                    </svg>
                </Modal>
            ) : (
                <Modal
                    centered
                    closable={true}
                    visible={showModal}
                    footer={[
                        <div className="button-wrap">
                            <a rel="noopener noreferrer" target="_blank" href={testURL}
                               className="btn btn-medium whit-border border-color-pink color-white bg-pink">Start</a>
                            <div
                                onClick={() => {
                                    setState(prevState => {
                                        return {
                                            ...prevState,
                                            showModal: false
                                        }
                                    })
                                }}
                                className="btn btn-medium whit-border border-color-pink color-pink bg-white"
                            >
                                Close
                            </div>
                        </div>
                    ]}
                    className="confirm-modal"
                    onCancel={() => {
                        setState(prevState => {
                            return {
                                ...prevState,
                                showModal: false
                            }
                        })
                    }}
                >
                    <div className="ant-modal-title">Hey Mathlete</div>
                    <div className="ant-modal-text">
                        Be noted that The Test will begin when you click "Start." You'll have 90 minutes to complete the test. Good Luck!
                    </div>
                </Modal>
            )}

            <div className="page-container">
                <div className="content-wrapper">
                    <div className="logo">
                        <img src={pandaLogo} alt="INTERNATIONAL MATH OLYMPIADalt"/>
                    </div>
                    <div className="info-box">
                        {/* <h2 class="color-1 fw-600">{!purchased ? '' : 'July 15 - Logic and Language of Logic'}</h2> */}
                        <h2 class="color-1 fw-600">{!purchased ? '' : 'SUMMER COMPETITION SERIES'}</h2>
                        <div className="description">
                            {/*The Summer Olympiad ended on August 1st. Results will be published from August 16 - August 20.*/}

                            {!purchased ?
                            (
                                <>
                                    You are registering for Summer Competition Series and Annual Space Panda Math Olympiad.
                                    <br/>
                                    <br/>
                                    <span class="color-1 fw-600">Summer Competitions start dates:</span>
                                    <br/>
                                    <span class="fw-600">July 15</span> - Logic and Language of Logic
                                    <br/>
                                    <span class="fw-600">August 1</span>5 - Patterns
                                    <br/>
                                    <span class="fw-600">September 15</span> - Numbers, Digits and Divisibility rules
                                    <br/>
                                    <br/>
                                    <span class="fw-600">November 14</span> - Space Panda Math Olympiad 2022!!
                                    <br/>
                                    <span class="color-1">Topics:</span>
                                    <br/>Patterns-Sequences-Magic Squares, Crypt-Arithmetic, Counting Methods, Combinations, Numbers and Operations, Numbers and Digits, Visual Math, Creative Geometry, Geometry Shapes, Solids, Balancing scales, Logic and Language of Logic.

                                </>
                            )
                            :
                            (
                                <>
                                    {/* The Competition dates: <span style={{fontWeight: 'bold'}}>Feb 14 - Feb 20</span>
                                    <br/>
                                    <br/>
                                    The Competition test button will be active every day from <span
                                    style={{fontWeight: 'bold'}}>Feb 14 to Feb 20</span> here, in your Space Panda
                                    Account Dashboard. You can take it any time during that period from your computer.
                                    <br/>
                                    <br/>
                                    Click on <span style={{fontWeight: 'bold'}}>Add Student or Grade</span> if you want
                                    to register another student or your student for other grade levels. */}
                                    
                                    <div class="fs-16">
                                    {/* The Competitions' and Olympiad's "Start test" buttons will be active starting July 15 in your Space Panda Account Dashboard.
                                    <br/><br/>
                                    <span class="fw-600">The Competitions will remain open until October 15, 2022</span>
                                    <br/> */}

                                    {/* August 15 - Patterns<br/>
                                    September 15 - Numbers, Digits and Divisibility rules
                                    <br/><br/>
                                    <span class="fw-600">November 14 - Space Panda Math Olympiad 2022!!</span> */}
                                    <br/>
                                    <TestButtons/>
                                    <br/>
                                    <span className="fw-600">Topics: </span>
                                    Patterns-Sequences-Magic Squares, Crypt-Arithmetic, Counting Methods, Combinations, Numbers and Operations, Numbers and Digits, Visual Math, Creative Geometry, Geometry Shapes, Solids, Balancing scales, Logic and Language of Logic.
                                    <br/><br/><hr/><br/>
                                    {/* We allow the registration of the students for two or more grades.
                                    Many students choose to participate at a level up, along with their current school grade. You can use the same email address  <a href="#" class="fw-500" onClick={logout}>click here</a>.
                                    <br/><br/> */}
                                    </div>
                                </>
                            )}

                            {/*If for some reason you didn't take the test, please contact <a href="mailto:team@spacepandamath.org">team@spacepandamath.org</a>.*/}
                            {!purchased ? (
                                <>
                                    {/* Topic: <span style={{fontWeight: 'bold'}}>COUNTING METHODS</span> */}
                                </>
                            ) : (
                                <>
                                    Please do not hesitate to contact <a href="mailto:team@spacepandamath.org">team@spacepandamath.org</a> if you have any questions.

                                </>
                            )}
                        </div>
                        {!purchased ? (
                                <>
                                    <div className="price-box m-t-0">
                                        {discount > 0 ? (
                                            <>
                                                <div className="wrap m-t-0">
                                                    <div className="old-price">
                                                        ${price}
                                                    </div>
                                                    <div className="new-price">
                                                        ${parseInt((price - discount)*100)/100}
                                                    </div>
                                                </div>
                                                <div className="discount-info">You have received ${discount} discount
                                                    because you’re registering
                                                    through <strong>{userInfo.CenterName || "Space Panda Online"}</strong> Learning
                                                    Center
                                                </div>
                                            </>
                                        ) : (
                                            <div className="price">
                                                ${price}
                                            </div>
                                        )}
                                    </div>

                                    <div onClick={() => {
                                        setState(prevState => {
                                            return {
                                                ...prevState,
                                                showModal: true
                                            }
                                        })
                                    }} className="btn btn-long whit-border border-color-pink color-white bg-pink m-t-0">Complete
                                        Registration
                                    </div>
                                </>
                            ) : ''
                            // <div className="button-wrap m-t-0"><TestButtons/></div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default StudentInner;
