/* eslint-disable no-template-curly-in-string */
import NotFound from "./components/pages/notfound";
import Login from "./components/pages/login";
import Forgot from "./components/pages/forgot";
import Reset from "./components/pages/reset";
import Register from "./components/pages/register";
import Verify from "./components/pages/verify";

let userType = 'student';

const routes = [
    {
        path: `/login`,
        pathname: "/login",
        exact: true,
        name: "Login",
        id: "login",
        component: Login
    },
    {
        path: `/register`,
        pathname: "/register",
        exact: true,
        name: "register",
        id: "register",
        component: Register
    },
    {
        path: `/verify/:userType(${userType})/:token`,
        pathname: "/verify",
        exact: true,
        name: "Verify Account",
        id: "verify",
        component: Verify
    },
    {
        path: `/forgot`,
        pathname: "/forgot",
        exact: true,
        name: "Forgot",
        id: "forgot",
        component: Forgot
    },
    {
        path: `/reset/:userType(${userType})/:token`,
        pathname: "/reset",
        exact: true,
        name: "Reset",
        id: "reset",
        component: Reset
    },
    {
        path: `/not-found`,
        pathname: "/not-found",
        exact: true,
        name: "Not Found",
        id: "notFound",
        component: NotFound
    },
];

export default routes;
