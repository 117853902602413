/* eslint-disable no-template-curly-in-string */
import React from 'react';
import {Router, Route, Switch, Redirect} from "react-router-dom";
import routes from "./routes";
import privateRoutes from "./privateRoutes";
import {
    history,
    getLocalStorage, setLocalStorage, removeLocalStorageItem
} from "./_helpers";
import Header from "./components/layout/header";
import Footer from "./components/layout/footer";
import './Assets/css/style.css'
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import {signout} from "./actions";

const apiUrl = process.env.REACT_APP_API;

const App = () => {
    const deviceId = getLocalStorage('deviceId')
    let user = getLocalStorage("user", true)
    const refreshAuthLogic = failedRequest => {
        let aaa = getLocalStorage("user", true)

        let refreshToken = aaa.refreshToken;

        return axios.post(`${apiUrl}/student/refresh-token`, {
            refreshToken: refreshToken,
            uniqueId: deviceId
        })
            .then(tokenRefreshResponse => {
                if (tokenRefreshResponse.data.error === null) {
                    let userToken = tokenRefreshResponse.data.payload.token;
                    let userRefreshToken = tokenRefreshResponse.data.payload.refreshToken;
                    let newData = {
                        token: userToken,
                        refreshToken: userRefreshToken,
                        firstName: aaa.firstName,
                        LastName: aaa.LastName,
                        CenterName:aaa.CenterName
                    };
                    removeLocalStorageItem("user");
                    setLocalStorage("user", newData, true)
                    failedRequest.response.config.headers["Authorization"] = userToken;
                    return Promise.resolve();
                } else {
                    signout()
                    window.location.reload()
                }
            });
    }
    createAuthRefreshInterceptor(axios, refreshAuthLogic);
    return (
        <>
            {
                user ?
                    <Router history={history}>
                        <Header/>
                        <Switch>
                            {privateRoutes.map((route, idx) => {
                                return route.component ? (
                                    <Route
                                        key={idx}
                                        path={route.path}
                                        exact={route.exact}
                                        name={route.name}
                                        render={props => (
                                            <route.component {...props} key={new Date().getTime()}/>
                                        )}
                                    />
                                ) : null;
                            })}

                            <Redirect to={{pathname: `/`}}/>
                        </Switch>
                        <Footer/>
                    </Router>
                    :
                    <Router history={history}>
                        <Header/>
                        <Switch>
                            {routes.map((route, idx) => {
                                return route.component ? (
                                    <Route
                                        key={idx}
                                        path={route.path}
                                        exact={route.exact}
                                        name={route.name}
                                        render={props => (
                                            <route.component {...props} key={new Date().getTime()}/>
                                        )}
                                    />
                                ) : null;
                            })}

                            <Redirect to={{pathname: `/login`}}/>
                        </Switch>
                        <Footer/>
                    </Router>
            }

        </>
    );
}

export default App;
