/* eslint-disable no-template-curly-in-string */
import Profile from "./components/pages/profile";
import StudentInner from "./components/pages/studentInner";

const privateRoutes = [
    {
        path: `/profile`,
        pathname: "/profile",
        exact: true,
        name: "Profile",
        id: "profile",
        component: Profile
    },
    {
        path: `/`,
        pathname: "/",
        exact: true,
        name: "Student Inner",
        id: "studentInner",
        component: StudentInner
    },
];

export default privateRoutes;
