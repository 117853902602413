/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from "react";
import axios from "axios";
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Select from "antd/lib/select";

const apiUrl = process.env.REACT_APP_API;

const Forgot = () => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        errorMessage: '',
        loader: false,
        success: false,
        email: '',
        studentGradeField: false,
        StudentGradeList: []
    })
    useEffect(() => {
        document.body.className = 'page-forgot';
        return () => {
            document.body.className = '';
        }
    }, []);

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not valid'
        },
    };

    const onFinish = async v => {
        let {email, studentGradeId} = v
        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })

        let postData = {
            email,
            userId: studentGradeId
        };
        let res = await axios.post(`${apiUrl}/student/forgotpassword/index`, postData);
        let {data} = res;
        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                    success: true,
                    email
                }
            })
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    errorMessage: data.error.message ? data.error.message : 'Something went wrong',
                    loader: false
                }
            })
        }
    };

    const resendMail = async () => {
        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })
        let {email} = state
        let postData = {
            email,
            type: "forgotpassword"
        }
        let res = await axios.post(`${apiUrl}/web/student/token/reset`, postData);
        let {data} = res;
        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                    success: true,
                }
            })
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    errorMessage: data.error.message ? data.error.message : 'Something went wrong',
                    loader: false
                }
            })
        }
    }

    const getStudentGradeListByEmail = async (email) => {
        let res = await axios.post(`${apiUrl}/student/users`, {
            email
        });
        let {data} = res;

        if (data.error === null) {
            if (data.payload.length) {
                setState(prevState => {
                    return {
                        ...prevState,
                        studentGradeField: true,
                        StudentGradeList: data.payload,
                    }
                })

                if (data.payload.length === 1) {
                    form.setFieldsValue({
                        studentGradeId: data.payload[0].id
                    });
                }
            } else {
                setState(prevState => {
                    return {
                        ...prevState,
                        studentGradeField: false,
                        StudentGradeList: [],
                    }
                })
            }

        }

    }

    let {loader, errorMessage, success, studentGradeField, StudentGradeList} = state
    return (
        <>
            {loader ? (
                <div id="loader-box" className="loader-box transparent">
                    <div className="atom">
                        <div className="electron"/>
                        <div className="electron"/>
                        <div className="electron"/>
                    </div>
                </div>
            ) : null}

            <div className="page-container">
                <div className="content-wrapper">
                    {!success ? (
                        <div className="form">
                            <Form
                                form={form}
                                name="login_form"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                validateMessages={validateMessages}
                            >
                                <div className="form-box">
                                    <h1 className="">Forgot Password</h1>
                                    <div className="description">
                                        Write your the email you used to register to Space Panda to reset your password.
                                    </div>
                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        rules={[{type: 'email', required: true}]}
                                    >
                                        <Input
                                            placeholder="Write your Email"
                                            onChange={(e) => {
                                                setState(prevState => {
                                                    return {
                                                        ...prevState,
                                                        studentGradeField: false

                                                    }
                                                })

                                                form.setFieldsValue({
                                                    studentGradeId: null
                                                });
                                                let value = e.target.value
                                                let filter = /^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i;
                                                if (!filter.test(value)) return false;

                                                return getStudentGradeListByEmail(value)
                                            }}
                                        />
                                    </Form.Item>

                                    {studentGradeField && (
                                        <Form.Item
                                            name="studentGradeId"
                                            label="Choose Student and Grade"
                                            rules={[{required: true, message: 'Student and Grade is required!'}]}
                                            style={{textAlign:'left'}}
                                        >
                                            <Select
                                                virtual={false}
                                                autoComplete={new Date().valueOf()}
                                                optionFilterProp="children"
                                                placeholder="Choose Student and Grade"
                                                allowClear
                                                showArrow
                                                filterOption={(input, option) => {
                                                    const string = `${option.children[0].props.children}, ${option.children[1]}, ${option.children[2]}`
                                                    return string.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                }
                                            >
                                                {Object.keys(StudentGradeList).map((key) => {
                                                    let {id, firstName, lastName, grade} = StudentGradeList[key]
                                                    return (
                                                        <Select.Option key={key}
                                                                       value={id}><span>{`${firstName} ${lastName}`}</span>{`, ${grade}`}
                                                        </Select.Option>
                                                    )
                                                })}
                                            </Select>

                                        </Form.Item>
                                    )}

                                    {errorMessage ? (
                                        <div className="response-box">
                                            {errorMessage}
                                        </div>
                                    ) : null}
                                    <Form.Item shouldUpdate={true}>
                                        {() => (
                                            <button
                                                className="btn btn-big whit-border border-color-pink bg-pink color-white"
                                                htmltype="submit">
                                                Send
                                            </button>
                                        )}
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                    ) : (
                        <>
                            <h2>Check your email for further instructions to reset your password.</h2>
                            {/*<div className="description">You can log in to your account</div>*/}
                            <div className="resend-box">
                                Didn't get email? <span onClick={resendMail}>Resend</span>
                            </div>
                        </>
                    )}

                </div>
            </div>
        </>
    );
}

export default Forgot;
