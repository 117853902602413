import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {getLocalStorage} from "../../_helpers";
import {signout} from "../../actions";
import Earth from "../../Assets/svg/earth.svg"
import Saturn from "../../Assets/svg/saturn.svg"
import Modal from "antd/es/modal";
import {ReactComponent as MedalIcon} from "../../Assets/svg/medal.svg";

const webSiteUrl = process.env.REACT_APP_WEBSITE_URL;
const centerUrl = process.env.REACT_APP_CENTER_URL;
const studentUrl = process.env.REACT_APP_STUDENT_URL;
const Header = () => {
    let user = getLocalStorage('user', true)
    let toolTipRef = useRef()

    let [state, setState] = useState({
        showUserToolTip: false,
        showModalResults: false,
        showModal: false,
        modalType: null
    })

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    })

    const handleClickOutside = event => {
        if (toolTipRef.current && !toolTipRef.current.contains(event.target) && !event.target.classList.contains("user-tooltip-btn")) {
            setState(prevState => {
                return {
                    ...prevState,
                    showUserToolTip: false
                }
            })
        }
    }
    const logout = () => {
        signout()
        window.location.reload()
    }
    const onSelect = key => {
        setState(prevState => {
            return {
                ...prevState,
                showUserToolTip: false,
                showMenu: false
            }
        })
    };

    const onMenuItemClick = key => {
        setState(prevState => {
            return {
                ...prevState,
                showMenu: false
            }
        })
    };
    const openModal = type => {
        setState(prevState => {
            return {
                ...prevState,
                showModal: true,
                modalType: type
            }
        })
    }
    const openModalResults = type => {
        setState(prevState => {
            return {
                ...prevState,
                showModalResults: true,
            }
        })
    }
    let {showUserToolTip, showMenu, showModal, showModalResults, modalType} = state
    return (
        <>
            <header className={showMenu ? 'menu-open' : ''}>
                <header-wrapper>
                    <header-logo>
                        <a rel="noopener noreferrer" aria-label="Space Panda Main Website"
                           href={webSiteUrl}>
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
                                 viewBox="0 0 318.26 60.71">
                                <defs>
                                    <linearGradient id="header-logo-linear-gradient" y1="18.08" x2="318.26" y2="18.08"
                                                    gradientUnits="userSpaceOnUse">
                                        <stop offset="0.11" stopColor="#62c3cb"/>
                                        <stop offset="0.17" stopColor="#75c9a9"/>
                                        <stop offset="0.25" stopColor="#a6d850"/>
                                        <stop offset="0.45" stopColor="#f0d74a"/>
                                        <stop offset="0.54" stopColor="#f0d74a"/>
                                        <stop offset="0.67" stopColor="#f6b156"/>
                                        <stop offset="0.83" stopColor="#f46869"/>
                                        <stop offset="0.93" stopColor="#ee4b6f"/>
                                    </linearGradient>
                                </defs>
                                <g id="Layer_2">
                                    <g id="Layer_1-2">
                                        <path className="cls-1"
                                              fill="url(#header-logo-linear-gradient)"
                                              d="M31,23.59C31,32,23.08,35.66,15,35.66c-5.95,0-11.93-2-15-5.59l4.72-6.34A16.62,16.62,0,0,0,14,26.9c3.39,0,5.81-1.45,5.81-3.87,0-5.55-18.18-1.28-18.18-11.93C1.64,2.91,9.56.8,17.05.8a47.76,47.76,0,0,1,8.1.75l-.27,8A23.06,23.06,0,0,0,17,8.1c-2.64,0-4.88.66-4.88,2.6C12.16,15.18,31,8.81,31,23.59Zm13.51,2.34,1.41,9.51-10.21.66L33.2,2.82A30.28,30.28,0,0,1,43.9.93c11.66,0,18,4.71,18,13C61.9,19.93,56.18,25.86,44.51,25.93ZM51,12.55c0-2.82-2.2-3.92-4.8-3.92a11.07,11.07,0,0,0-4.09.84l1.23,8.63C47.15,17.35,51,15.63,51,12.55ZM80.93.89,92.78,34.83l-10.24.44-2.07-8.06L72,27.52l-1.32,7-10.08.53,9-33.23Zm-2,20.47L75.68,11,73.17,21.36ZM110.67,25c-3.79,0-7-2.24-7-7.3,0-3.48,2.51-6.87,8.06-6.87a7.18,7.18,0,0,1,3.25.75l2.73-7.88a18.19,18.19,0,0,0-6.64-1.32c-11.76,0-17.52,8.9-17.52,17,0,8.78,7,16,17.08,16a25.34,25.34,0,0,0,8.27-1.5l-3.13-10.15A11.32,11.32,0,0,1,110.67,25Zm21.17.33-.66-4.75,9.29-.79-.71-7-9.46.7L129.86,9,142,7.61,141.39,0,120.66,2.47l2.51,32.22,21.17.3-.62-10.83Zm43.73.58L177,35.44l-10.19.66L164.26,2.82A30.28,30.28,0,0,1,175,.93c11.66,0,18,4.71,18,13C193,19.93,187.24,25.86,175.57,25.93Zm6.54-13.38c0-2.82-2.2-3.92-4.8-3.92a11.07,11.07,0,0,0-4.09.84l1.23,8.63C178.23,17.35,182.11,15.63,182.11,12.55ZM212,.89l11.84,33.94-10.23.44-2.06-8.06-8.5.31-1.32,7-10.08.53,9-33.24Zm-2,20.47L206.76,11l-2.5,10.39Zm34.65-2.24L236,2.53,225.7,4.47l.8,29.8,9.42,1-.8-17.79,12,18.67,4.95-1,3-33.36-11,.61Zm42.52-.57C287.2,28.81,274.9,36,262,36a35.15,35.15,0,0,1-3.7-.22l.31-32.48c1.86-.13,3.7-.22,5.55-.22C277.45,3.05,287.2,8.29,287.2,18.55Zm-10.54-.22c0-3.44-2.86-6.78-8.5-6.78h-.75l-.66,14.31C273.36,25.86,276.66,22,276.66,18.33Zm41.6,16.45L308,35.27,306,27.21l-8.49.31-1.32,7-10.08.53,9-33.24L306.44.89ZM304.43,21.36,301.17,11l-2.5,10.39Z"/>
                                        <g className="cls-2">
                                            <g className="cls-2">
                                                <path className="cls-3"
                                                      fill="#fff"
                                                      d="M34.11,60.48l-5.6-.57.21-2.65,1.22.07.22-5.41H29l-.08-2.56,6.24-.26-.06,2.82H33.65l-.4,5.66,1,.06Z"/>
                                                <path className="cls-3"
                                                      fill="#fff"
                                                      d="M45.07,60.25l-1.65.33-4-6.18.26,5.89L36.58,60l-.26-9.88,3.4-.64L42.6,55l-.21-5.56,3.66-.2Z"/>
                                                <path className="cls-3" fill="#fff"
                                                      d="M53,60.33l-3.16.38.21-7.93-2.8.19,0-3.9L56,49.6,56,52.37l-3.24.22Z"/>
                                                <path className="cls-3"
                                                      fill="#fff"
                                                      d="M65,60.2l-7-.1-.83-10.68L64,48.6l.19,2.53-4,.47.14,1.48,3.14-.23.23,2.32-3.08.26L60.84,57l3.94-.4Z"/>
                                                <path className="cls-3"
                                                      fill="#fff"
                                                      d="M75.27,59l-3.09,1.6-1.75-4.07a6.76,6.76,0,0,1-1.2.51l.34,3-3.18.49-.28-11.13a14.15,14.15,0,0,1,3.18-.35,11.3,11.3,0,0,1,2,.17,6.76,6.76,0,0,1,1.68.55,3.11,3.11,0,0,1,1.19,1,2.6,2.6,0,0,1,.43,1.48,3.87,3.87,0,0,1-2,3.11Zm-6.68-7.17.32,2.66a4.94,4.94,0,0,0,1.91-.68,1.38,1.38,0,0,0,.76-1.16c0-.73-.46-1.09-1.37-1.09A5.59,5.59,0,0,0,68.59,51.87Z"/>
                                            </g>
                                            <g className="cls-2">
                                                <path className="cls-3"
                                                      fill="#fff"
                                                      d="M84.43,60.25l-1.65.33-4-6.18.27,5.89L75.94,60l-.27-9.88,3.4-.64L82,55l-.22-5.56,3.66-.2Z"/>
                                            </g>
                                            <g className="cls-2">
                                                <path className="cls-3"
                                                      fill="#fff"
                                                      d="M96.4,60.13l-3.4.15-.69-2.67-2.81.1L89.06,60l-3.34.17,3-11,3.77-.31Zm-4.6-4.45-1.08-3.44-.83,3.44Z"/>
                                            </g>
                                            <g className="cls-2">
                                                <path className="cls-3" fill="#fff"
                                                      d="M101.05,60.33l-3.17.38.22-7.93L95.3,53v-3.9l8.76.53L104,52.37l-3.24.22Z"/>
                                            </g>
                                            <g className="cls-2">
                                                <path className="cls-3"
                                                      fill="#fff"
                                                      d="M110.12,60.48l-5.6-.57.2-2.65,1.23.07.22-5.41H105l-.09-2.56,6.25-.26-.06,2.82h-1.44l-.4,5.66,1,.06Z"/>
                                                <path className="cls-3"
                                                      fill="#fff"
                                                      d="M123.2,54.75a5.08,5.08,0,0,1-1.7,4,5.84,5.84,0,0,1-4,1.53,5.34,5.34,0,0,1-5.37-5.4,6,6,0,0,1,1.5-4.15A5.16,5.16,0,0,1,117.64,49a5.56,5.56,0,0,1,3,.81A5.18,5.18,0,0,1,122.57,52,6.22,6.22,0,0,1,123.2,54.75Zm-5.52,2.75a1.9,1.9,0,0,0,1.55-.72,2.91,2.91,0,0,0,.58-1.9,2.49,2.49,0,0,0-.58-1.64,1.9,1.9,0,0,0-1.56-.71,2.2,2.2,0,0,0-1.7.71,2.66,2.66,0,0,0-.64,1.84,2.55,2.55,0,0,0,.61,1.72A2.16,2.16,0,0,0,117.68,57.5Z"/>
                                            </g>
                                            <g className="cls-2">
                                                <path className="cls-3"
                                                      fill="#fff"
                                                      d="M133.22,60.25l-1.65.33-4-6.18.27,5.89L124.73,60l-.27-9.88,3.4-.64,2.89,5.5-.22-5.56,3.66-.2Z"/>
                                            </g>
                                            <g className="cls-2">
                                                <path className="cls-3"
                                                      fill="#fff"
                                                      d="M145.19,60.13l-3.4.15-.69-2.67-2.81.1L137.85,60l-3.34.17,3-11,3.77-.31Zm-4.6-4.45-1.08-3.44-.83,3.44Z"/>
                                            </g>
                                            <g className="cls-2">
                                                <path className="cls-3" fill="#fff"
                                                      d="M153.32,60.07l-6.87.5-.66-11.34,3.69-.29-.1,8.48,3.84-.31Z"/>
                                                <path className="cls-3" fill="#fff"
                                                      d="M174.5,60.22l-3.65-.12-.42-6.56-2.33,6.31-1.62-.1-2.09-6.08L164,60.12l-3.43-.57L162.32,49l3.59.28,1.87,5.12,2-4.8,3.66-.13Z"/>
                                            </g>
                                            <g className="cls-2">
                                                <path className="cls-3" fill="#fff"
                                                      d="M185.83,60.13l-3.4.15-.69-2.67-2.81.1L178.49,60l-3.34.17,3-11,3.77-.31Zm-4.6-4.45-1.08-3.44-.83,3.44Z"/>
                                            </g>
                                            <g className="cls-2">
                                                <path className="cls-3" fill="#fff"
                                                      d="M190.49,60.33l-3.17.38.22-7.93-2.8.19v-3.9l8.76.53-.08,2.77-3.23.22Z"/>
                                                <path className="cls-3"
                                                      fill="#fff"
                                                      d="M203,60.2l-3.18-.07-.05-3.84-1.81.18-.1,4-3-.23-.17-10.49,3.3-.18-.11,4.51,1.83,0-.05-4.37,2.89-.24Z"/>
                                                <path className="cls-3"
                                                      fill="#fff"
                                                      d="M221.4,54.75a5.08,5.08,0,0,1-1.69,4,5.84,5.84,0,0,1-4.05,1.53,5.34,5.34,0,0,1-5.37-5.4,6,6,0,0,1,1.5-4.15A5.15,5.15,0,0,1,215.84,49a5.57,5.57,0,0,1,3,.81A5.18,5.18,0,0,1,220.78,52,6.35,6.35,0,0,1,221.4,54.75Zm-5.51,2.75a1.87,1.87,0,0,0,1.54-.72,2.92,2.92,0,0,0,.59-1.9,2.49,2.49,0,0,0-.58-1.64,1.91,1.91,0,0,0-1.57-.71,2.18,2.18,0,0,0-1.69.71,2.87,2.87,0,0,0,0,3.56A2.19,2.19,0,0,0,215.89,57.5Z"/>
                                            </g>
                                            <g className="cls-2">
                                                <path className="cls-3" fill="#fff"
                                                      d="M230.48,60.07l-6.87.5L223,49.23l3.69-.29-.1,8.48,3.84-.31Z"/>
                                            </g>
                                            <g className="cls-2">
                                                <path className="cls-3"
                                                      fill="#fff"
                                                      d="M236.5,60.39l-3.59.31-.06-5.28-4.07-5.31,4-1.36L235.17,53l2.38-4.23,2.67,1-3.76,5.73Z"/>
                                            </g>
                                            <g className="cls-2">
                                                <path className="cls-3"
                                                      fill="#fff"
                                                      d="M253.66,60.22,250,60.1l-.43-6.56-2.33,6.31-1.62-.1-2.09-6.08-.42,6.45-3.43-.57L241.48,49l3.59.28,1.87,5.12,2-4.8,3.66-.13Z"/>
                                            </g>
                                            <g className="cls-2">
                                                <path className="cls-3"
                                                      fill="#fff"
                                                      d="M259.19,60.33l-3.39.24-.83-11a9.86,9.86,0,0,1,3.55-.63A7.41,7.41,0,0,1,263,50a3.69,3.69,0,0,1,1.53,3.18,3.12,3.12,0,0,1-.36,1.46,4.06,4.06,0,0,1-1.06,1.27,5.25,5.25,0,0,1-1.82.91,8.89,8.89,0,0,1-2.52.33Zm-1.26-8.59.41,2.86a5.24,5.24,0,0,0,1.8-.67,1.37,1.37,0,0,0,.74-1.17,1.15,1.15,0,0,0-.44-1,1.91,1.91,0,0,0-1.15-.32A3.56,3.56,0,0,0,257.93,51.74Z"/>
                                            </g>
                                            <g className="cls-2">
                                                <path className="cls-3"
                                                      fill="#fff"
                                                      d="M270.71,60.48l-5.6-.57.2-2.65,1.23.07.22-5.41h-1.17l-.08-2.56,6.24-.26-.06,2.82h-1.44l-.4,5.66,1,.06Z"/>
                                            </g>
                                            <g className="cls-2">
                                                <path className="cls-3"
                                                      fill="#fff"
                                                      d="M282.56,60.13l-3.4.15-.69-2.67-2.81.1L275.22,60l-3.34.17,3-11,3.77-.31ZM278,55.68l-1.08-3.44-.83,3.44Z"/>
                                                <path className="cls-3"
                                                      fill="#fff"
                                                      d="M283.51,60.45l.1-10.75c.7-.05,1.31-.07,1.84-.07A10.21,10.21,0,0,1,291,51a4.24,4.24,0,0,1,2.11,3.8,4.34,4.34,0,0,1-1.2,3,7.61,7.61,0,0,1-3.1,2.06,11.36,11.36,0,0,1-4,.72A11.23,11.23,0,0,1,283.51,60.45Zm3-8-.21,4.72a3.56,3.56,0,0,0,2.41-.74,2.25,2.25,0,0,0,.87-1.75,2.08,2.08,0,0,0-.75-1.59,3,3,0,0,0-2.07-.66h-.25Z"/>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </header-logo>

                    <header-menu>
                        <header-menu-item>
                            <div className="btn btn-small color-gold whit-border border-color-gold whit-icon"
                                 onClick={() => {
                                     openModalResults()
                                 }}><MedalIcon/><span>Results</span></div>
                        </header-menu-item>

                        <header-menu-item>
                            <a rel="noopener noreferrer" aria-label="Participating Learning Centers"
                               href={`${webSiteUrl}/centers`}>Participating Learning Centers</a>
                        </header-menu-item>

                        {/*<header-menu-item>*/}
                        {/*    <a rel="noopener noreferrer" aria-label="Space Panda For Learning Centers"*/}
                        {/*       href={`${centerUrl}/register`}>For*/}
                        {/*        Learning*/}
                        {/*        Centers</a>*/}
                        {/*</header-menu-item>*/}

                        {/*<header-menu-item>*/}
                        {/*    <Link aria-label="Space Panda For Students" to="/register">For Students</Link>*/}
                        {/*</header-menu-item>*/}

                        <header-menu-item>
                            <a rel="noopener noreferrer" aria-label="Space Panda Sponsors"
                               href={`${webSiteUrl}/#sponsors`}>Sponsors</a>
                        </header-menu-item>

                        <header-menu-item>
                            <a rel="noopener noreferrer" aria-label="Space Panda About"
                               href={`${webSiteUrl}#about`}>About</a>
                        </header-menu-item>

                        {/*<header-menu-item>*/}
                        {/*    <a rel="noopener noreferrer" aria-label="Space Panda Sample Problem"*/}
                        {/*       href="https://drive.google.com/drive/folders/1W4PX9uUgzjZXtzrMyRi3D3Jp0C86mQ0B">Sample*/}
                        {/*        Problem</a>*/}
                        {/*</header-menu-item>*/}

                        {user ? (
                            <>
                                <header-menu-item class="user-tooltip">
                                    <div
                                        className="user-tooltip-btn btn btn-small color-white whit-border border-color-white whit-icon"
                                        onClick={() => {
                                            setState(prevState => {
                                                return {
                                                    ...prevState,
                                                    showUserToolTip: true
                                                }
                                            })
                                        }}>
                                        {user.firstName + ' ' + user.LastName}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.556" height="8"
                                             viewBox="0 0 11.556 8">
                                            <path
                                                d="M6.855,12.279l.065.088,4.911,6.523a.866.866,0,0,0,.693.359.878.878,0,0,0,.693-.359l4.907-6.511.083-.108a.714.714,0,0,0,.1-.363.631.631,0,0,0-.6-.659H7.349a.631.631,0,0,0-.6.659.7.7,0,0,0,.1.371Z"
                                                transform="translate(-6.75 -11.249)" fill="#fff"/>
                                        </svg>
                                    </div>
                                    {
                                        showUserToolTip ? (
                                            <div className="user-tooltip-box" ref={toolTipRef}>
                                                <Link onClick={onSelect} to="/">Dashboard</Link>
                                                <Link onClick={onSelect} to="/profile">Profile</Link>
                                                <div onClick={logout}>Logout</div>
                                            </div>
                                        ) : null
                                    }
                                </header-menu-item>

                            </>
                        ) : (
                            <header-menu-item class="button-wrap">
                                <div onClick={() => {
                                    openModal('login')
                                    onMenuItemClick()
                                }}
                                     className="btn btn-small color-white whit-border border-color-white"
                                >
                                    Sign in
                                </div>
                                <div onClick={() => {
                                    openModal('register')
                                    onMenuItemClick()
                                }}
                                     className="btn btn-small color-white whit-border bg-pink border-color-pink"
                                >
                                    Sign Up
                                </div>
                            </header-menu-item>
                        )}
                    </header-menu>

                    <header-menu-button class={showMenu ? 'clicked' : ''} onClick={() => {

                        if (showMenu) {
                            document.body.classList.remove('overflow-hidden')
                            setState(prevState => {
                                return {
                                    ...prevState,
                                    showMenu: false
                                }
                            })
                        } else {
                            document.body.classList.add('overflow-hidden')
                            setState(prevState => {
                                return {
                                    ...prevState,
                                    showMenu: true
                                }
                            })
                        }

                    }}>
                        <i>
                            <span/>
                            <span/>
                            <span/>
                            <span/>
                        </i>
                    </header-menu-button>
                </header-wrapper>
            </header>
            <Modal
                centered
                closable={false}
                visible={showModal}
                footer={null}
                className="ant-modal-login-register"
                onCancel={() => {
                    setState(prevState => {
                        return {
                            ...prevState,
                            showModal: false,
                            modalType: null
                        }
                    })
                }}
            >
                <div className="ant-modal-title">I’m a</div>
                <div className="wrap-box">
                    <a href={`${centerUrl}/${modalType === 'login' ? 'login' : 'register'}`} rel="noopener noreferrer"
                       className="center">
                        <div className="icon">
                            <img src={Saturn} alt=""/>
                        </div>
                        <div className="title">Learning Center</div>
                    </a>

                    <a href={`${studentUrl}/${modalType === 'login' ? 'login' : 'register'}`} rel="noopener noreferrer"
                       className="student">
                        <div className="icon">
                            <img src={Earth} alt=""/>
                        </div>
                        <div className="title">Student</div>
                    </a>
                </div>
            </Modal>


            <Modal
                centered
                closable={false}
                visible={showModalResults}
                footer={null}
                className="ant-modal-results"
                onCancel={() => {
                    setState(prevState => {
                        return {
                            ...prevState,
                            showModalResults: false,
                        }
                    })
                }}
            >
                <div className="ant-modal-title">Results</div>
                <div className="wrap-box-results">
                    <a href={`${process.env.REACT_APP_WEBSITE_URL}/results-olymp-summer-2021`} rel="noopener noreferrer"
                       className="center">
                        1. Summer Olympiad 2021
                    </a>

                    <a href={`${process.env.REACT_APP_WEBSITE_URL}/results-counting-methods-comp-2022`} rel="noopener noreferrer"
                       className="results-row">
                        2. Counting Methods Competition 2022
                    </a>
                </div>
            </Modal>
        </>
    );
}

export default Header;
